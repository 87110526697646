import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

const SidebarLayout = ({ location, type }) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx(filter: {fields: {slug: {ne: "/"}}}) {
          edges {
            node {
              fields {
                slug
                title
              }
              frontmatter {
                rank
              }
            }
          }
        }
      }
    `}
    render={({ allMdx }) => {
      const types = [{
        type: 'install',
        name: '安装',
        items: []
      }, {
        type: 'guides',
        name: '手册',
        items: []
      }, {
        type: 'documentation',
        name: '文档',
        items: [
          {
            url: 'https://hsd-dev.org/api-docs',
            title: 'API手册',
            rank: 1
          },
          {
            url: 'https://hsd-dev.org/docs',
            title: '接口文档',
            rank: 2
          }
        ]
      }, {
        type: 'protocol',
        name: '协议',
        items: [
          {
            url: 'https://www.hnsfans.com/hns-whitepaper/',
            title: '白皮书',
            rank: 1
          }
        ]
      }
      ];

      const mode = type === 'side' ? 'd-none d-lg-block' : 'd-block d-lg-none h-auto';

      const files = {};


      for (const { node } of allMdx.edges) {
        const belongs = node.fields.slug.split('/')[1];
        if (!files[belongs]) {
          files[belongs] = [];
        }
        files[belongs].push({
          url: node.fields.slug,
          title: node.fields.title,
          rank: node.frontmatter.rank
        });
      }

      for (const item in files) {
        files[item].sort((a, b) => {
          return (a.rank || 0) - (b.rank || 0);
        });
      }

      for (const item of types) {
        if (files[item.type]) {
          item.items = files[item.type];
        }
      }

      return (
        <div className={`sidebar ${mode}`}>
          <ul className={'mt-lg-5 mt-2 ml-3'}>
            {types.map((item, index) => (
              <li key={index} className={'mb-4'}>
                <div className={'mb-2 sidebar-name font-weight-bold'}>{item.name}</div>
                <ul>
                  {item.items.map((info, i) => (
                    <li key={`${index}-${i}`} className={'mb-1'}>
                      <Link target="_blank" to={info.url} className={info.url === location.pathname ? 'active sidebar-doc' : 'sidebar-doc'}>
                        {info.title}
                      </Link>
                    </li>
                  ))
                  }
                </ul>
              </li>
            ))}
          </ul>
        </div>
      );
    }}
  />
);

export default SidebarLayout;
