import React from 'react';
import { Link } from 'gatsby';
import { Navbar, Nav, Image } from 'react-bootstrap';

import Sidebar from './sidebar';
import logo from '../images/hsd-logo.svg';

const Header = ({ location }) => (
  <Navbar bg="dark" variant="dark" expand="lg">
    <Navbar.Brand className={'my-lg-3'}>
      <Link to={'/'} className={'text-light'}>
        <Image src={logo} width="40" height="40" className="mr-3" />
        HandShake 中文手册
      </Link>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto d-lg-none d-xl-block">
        <Sidebar location={location} type={'head'} />
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default Header;
