import React, { Component } from 'react';
import { graphql } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import Helmet from 'react-helmet';

import Layout from '../components/layout';

export default class MDXRuntimeTest extends Component {
  render() {
    const { data } = this.props;

    if (!data) {
      return null;
    }
    const { mdx } = data;

    console.log(mdx);
    const title = mdx.fields.slug === '/' ? 'HandShake中文手册' : mdx.fields.title;

    return (
      <Layout {...this.props}>
        <Helmet title={title} />
        <div className={'pb-3'}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    mdx(fields: { id: { eq: $id } }) {
      fields {
        id
        title
        slug
      }
      body
      tableOfContents
      parent {
        ... on File {
          relativePath
        }
      }
    }
  }
`;
