import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

const SidebarLayout = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx {
          edges {
            node {
              fields {
                slug
              }
              tableOfContents
            }
          }
        }
      }
    `}
    render={({ allMdx }) => {

      const data = allMdx.edges.filter(item => {
        return item.node.fields.slug === location.pathname;
      })[0];

      if (!data) {
        return null;
      }

      return (
        <div className={'rightSideBar'}>
          <ul className={'mt-5 rightSideBar-list'}>
            {data.node.tableOfContents.items.map((heading, index) => (
              <li key={index} className={'pl-3 pb-2'}>
                <Link to={location.pathname + heading.url} className={'rightSideBar-h1'}>{heading.title}</Link>
                <ul>
                  {heading.items?heading.items.map((next, i) => (
                    <li key={index + '-' + i} className={'pl-3'}>
                      <Link to={location.pathname + next.url} className={'rightSideBar-h2'}>{next.title}</Link>
                    </li>
                  )):null}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      );
    }}
  />
);

export default SidebarLayout;
