import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { Row, Col, Container } from 'react-bootstrap';

import mdxComponents from './mdxComponents';
import Sidebar from './sidebar';
import RightSidebar from './rightSidebar';
import Header from './header';

const Layout = ({ children, location }) => (
  <>
    <Header location={location} />
    <MDXProvider components={mdxComponents}>
      <Container fluid={true}>
        <Row>
          <Col lg={2} className={'px-0'}>
            <Sidebar location={location} type={'side'} />
            </Col>
          <Col lg={8} className={'main'}>{children}</Col>
          <Col className={'pr-0'}><RightSidebar location={location} /></Col>
        </Row>
      </Container>
    </MDXProvider>
  </>
);

export default Layout;
